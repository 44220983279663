import React from 'react';
import PropTypes from 'prop-types';
import Showdown from 'showdown';
import { Form } from 'react-bootstrap';

export default function InfrastructureIndicator({
  indicator,
  register,
  formChanged,
  selfAssessment,
  setValue,
  sectionRef,
}) {
  const md = new Showdown.Converter();
  let name = 'Unknown';
  if (
    selfAssessment.response &&
    selfAssessment.response[`indicator_${indicator.identifier}_updated_by`]
  ) {
    let userId =
      selfAssessment.response[`indicator_${indicator.identifier}_updated_by`];
    if (selfAssessment.owner.id == userId) {
      name =
        selfAssessment.owner.first_name + ' ' + selfAssessment.owner.last_name;
    } else {
      selfAssessment.users.map((usr) => {
        if (usr.id == userId) {
          name = usr.first_name + ' ' + usr.last_name;
          return;
        }
      });
    }
  }
  return (
    <div
      key={indicator.identifier}
      id={`indicator_${indicator.identifier}`}
      className="my-5"
      ref={sectionRef}
    >
      <p className="text-uppercase mb-0">
        <strong>
          Indicator {indicator.number} <span className="px-2">|</span>
          <span className="text-idm-pink">{indicator.area}</span>
        </strong>
      </p>
      <h3
        dangerouslySetInnerHTML={{
          __html: md.makeHtml(indicator.summary),
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: md.makeHtml(indicator.content),
        }}
      />
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td className="w-25">
              <div className="custom-control custom-radio mb-0">
                <input
                  type="radio"
                  ref={register}
                  name={`indicator_${indicator.identifier}`}
                  id={`indicator_${indicator.identifier}Initial`}
                  value="1"
                  onChange={() => formChanged(indicator.identifier, {"score": "1"})}
                  className="custom-control-input"
                />
                <label
                  htmlFor={`indicator_${indicator.identifier}Initial`}
                  className="tag mb-0 custom-control-label"
                ></label>
              </div>
              <label
                htmlFor={`indicator_${indicator.identifier}Initial`}
                className="tag mt-2"
              >
                Initial Stages
              </label>
            </td>
            <td className="w-25">
              <div className="custom-control custom-radio mb-0">
                <input
                  type="radio"
                  ref={register}
                  name={`indicator_${indicator.identifier}`}
                  id={`indicator_${indicator.identifier}Development`}
                  value="2"
                  onChange={() => formChanged(indicator.identifier, {"score": "2"})}
                  className="custom-control-input"
                />
                <label
                  htmlFor={`indicator_${indicator.identifier}Development`}
                  className="tag mb-0 custom-control-label"
                ></label>
              </div>
              <label
                htmlFor={`indicator_${indicator.identifier}Development`}
                className="tag mt-2"
              >
                In Development
              </label>
            </td>
            <td className="w-25">
              <div className="custom-control custom-radio mb-0">
                <input
                  type="radio"
                  ref={register}
                  value="3"
                  name={`indicator_${indicator.identifier}`}
                  id={`indicator_${indicator.identifier}InPlace`}
                  onChange={() => formChanged(indicator.identifier, {"score": "3"})}
                  className="custom-control-input"
                />
                <label
                  htmlFor={`indicator_${indicator.identifier}InPlace`}
                  className="tag mb-0 custom-control-label"
                ></label>
              </div>
              <label
                htmlFor={`indicator_${indicator.identifier}InPlace`}
                className="tag mt-2"
              >
                In Place
              </label>
            </td>
            <td className="w-25">
              <div className="custom-control custom-radio mb-0">
                <input
                  type="radio"
                  ref={register}
                  name={`indicator_${indicator.identifier}`}
                  id={`indicator_${indicator.identifier}Established`}
                  value="4"
                  onChange={() => formChanged(indicator.identifier, {"score": "4"})}
                  className="custom-control-input"
                />
                <label
                  htmlFor={`indicator_${indicator.identifier}Established`}
                  className="tag mb-0 custom-control-label"
                ></label>
              </div>
              <label
                htmlFor={`indicator_${indicator.identifier}Established`}
                className="tag mt-2"
              >
                Well Established
              </label>
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              <label
                htmlFor={`indicator_${indicator.identifier}Initial`}
                dangerouslySetInnerHTML={{
                  __html: md.makeHtml(indicator.initial),
                }}
              />
            </td>
            <td>
              {' '}
              <label
                htmlFor={`indicator_${indicator.identifier}Development`}
                dangerouslySetInnerHTML={{
                  __html: md.makeHtml(indicator.development),
                }}
              />
            </td>
            <td>
              {' '}
              <label
                htmlFor={`indicator_${indicator.identifier}InPlace`}
                dangerouslySetInnerHTML={{
                  __html: md.makeHtml(indicator.in_place),
                }}
              />
            </td>
            <td>
              {' '}
              <label
                htmlFor={`indicator_${indicator.identifier}Established`}
                dangerouslySetInnerHTML={{
                  __html: md.makeHtml(indicator.established),
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Form.Group
        className="mb-3"
        controlId={`indicator_${indicator.identifier}_notes`}
      >
        <Form.Label>Notes:</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          ref={register}
          name={`indicator_${indicator.identifier}_notes`}
          onBlur={() => formChanged(indicator.identifier, {'notes': indicator.identifier})}
        />
      </Form.Group>
      {selfAssessment.response &&
        selfAssessment.response[`indicator_${indicator.identifier}`] && (
          <p>
            <small>
              <em>
                Last updated by {name} on{' '}
                {
                  selfAssessment.response[
                    `indicator_${indicator.identifier}_updated_at`
                  ]
                }
                .{' '}
                <a
                  onClick={(e) => {
                    setValue(`indicator_${indicator.identifier}_notes`, null);
                    setValue(`indicator_${indicator.identifier}`, null);
                    setValue(
                      `indicator_${indicator.identifier}_updated_at`,
                      null
                    );
                    setValue(
                      `indicator_${indicator.identifier}_updated_by`,
                      null
                    );
                    e.preventDefault();
                    formChanged(indicator.identifier, null);
                  }}
                  href="#"
                >
                  Reset
                </a>
              </em>
            </small>
          </p>
        )}
    </div>
  );
}

InfrastructureIndicator.propTypes = {
  indicator: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  formChanged: PropTypes.func.isRequired,
  selfAssessment: PropTypes.object.isRequired,
  sectionRef: PropTypes.object.isRequired,
};

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Tab,
  ListGroup,
  ProgressBar,
  Nav,
  Form,
  Modal,
  Button,
} from 'react-bootstrap';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useForm } from 'react-hook-form';
import Showdown from 'showdown';
import Masthead from '@/components/layout/masthead';
import SEO from '@/components/layout/seo';
import InfrastructureIndicator from '@/components/InfrastructureIndicator';
import ImplementationIndicator from '@/components/ImplementationIndicator';
import useScrollSpy from 'react-use-scrollspy';
import { FaTrash, FaShare } from 'react-icons/fa';
import Initials from '@/components/initials';
import {
  getAssessment,
  saveAssessment,
  deleteAssessment,
  getUser,
  inviteToAssessment,
} from '@/services/api';
import {
  calculateProgress,
  getIndicators,
  getOverviews,
  organizeIndicators,
} from '@/services/idm';
import { Element, scroller } from 'react-scroll';

const Page = ({ id }) => {
  const data = useStaticQuery(graphql`
    {
      allIndicatorsXlsxIndicators {
        nodes {
          identifier
          category
          group
          number
          type
          summary
          in_place
          initial
          area
          content
          development
          established
        }
      }
    }
  `);
  const allIndicators = data.allIndicatorsXlsxIndicators.nodes;
  const tabs = getOverviews(allIndicators);
  const indicators = getIndicators(allIndicators);
  const orderedIndicators = organizeIndicators(allIndicators);

  const { register, getValues, setValue, control } = useForm();

  const [selfAssessment, setSelfAssessment] = useState({});
  const [progress, setProgress] = useState({});

  const [showDeleteModal, setDeleteModalShow] = useState(false);
  const [idmSection, setIdmSection] = useState('PL');
  const handleDeleteModalClose = () => setDeleteModalShow(false);

  const [showShareModal, setShareModalShow] = useState(false);

  const [assessmentScores, setAssessmentScore] = useState({});

  const handleShareModalClose = () => setShareModalShow(false);

  const sectionTop = useRef(null);

  const { register: registerShare, getValues: getValuesShare } = useForm();

  const shareFormChanged = async (e) => {
    e.preventDefault();
    let resp = await inviteToAssessment(id, getValuesShare());
    if (!resp.error) {
      setShareModalShow(false);
    } else {
    }
  };

  useEffect(() => {
    async function getData() {
      let sa = await getAssessment(id);
      if (sa.error) {
        navigate('/app/idm/');
      } else {
        setSelfAssessment(sa.data);
        console.log(sa.data);
        let allData = sa.data.response;
        getAssessmentData(sa.data);
        if (sa.data.response) {
          for (var field in sa.data.response) {
            setValue(field, sa.data.response[field], { shouldDirty: true });
          }
          doCalculateProgress(sa.data.response);
        }
      }
    }
    getData();
  }, [idmSection]);

  const doCalculateProgress = (data) => {
    setProgress(calculateProgress(data, indicators));
    setAssessmentScore(data);
  };

  const getAssessmentData = (data) => {
    const copyAPIData = [data.response];
    setAssessmentScore(copyAPIData);
  }

  const formChanged = async (identifier, formInput) => {
    // Not using getValues anymore
    //const values = getValues();
    const values = assessmentScores;
    const me = getUser();
    let currentSelfAssessment = selfAssessment;
    currentSelfAssessment.response = { ...selfAssessment.response, ...values };
    if(formInput == null){
      currentSelfAssessment.response[`indicator_${identifier}`] = null;
      currentSelfAssessment.response[`indicator_${identifier}_notes`] = null;
      currentSelfAssessment.response[`indicator_${identifier}_source`] = null;
      currentSelfAssessment.response[`indicator_${identifier}_specific_source`] = null;
    }
    else {
      if(formInput.score) {
        currentSelfAssessment.response[`indicator_${identifier}`] = formInput.score;
      }
      //notes
      if(formInput.notes){
        const notesValue = document.getElementById(`indicator_${identifier}_notes`).value;
        currentSelfAssessment.response[`indicator_${identifier}_notes`] = notesValue;
      }//source
      if (formInput.source){
        currentSelfAssessment.response[`indicator_${identifier}_source`] = formInput.source;
      }
      //specific_source
      if(formInput['specific_source']){
        const sourceValue = document.getElementById(`indicator_${identifier}SpecificSource`).value;
        currentSelfAssessment.response[`indicator_${identifier}_specific_source`] = sourceValue;
      }
      currentSelfAssessment.response[`indicator_${identifier}_updated_by`] =
      me.id;
      currentSelfAssessment.response[`indicator_${identifier}_updated_at`] = 
      new Date().toLocaleDateString();
    }
    setSelfAssessment(currentSelfAssessment);
    await setAssessmentScore(currentSelfAssessment);
    await saveAssessment(currentSelfAssessment);
    doCalculateProgress(currentSelfAssessment.response);
  };

  const md = new Showdown.Converter();

  const saveName = async (e) => {
    let currentSelfAssessment = selfAssessment;
    currentSelfAssessment.name = e.target.value;
    setSelfAssessment(currentSelfAssessment);
    await saveAssessment(currentSelfAssessment);
  };

  const handleDeleteAssessment = async (e) => {
    let resp = await deleteAssessment(selfAssessment.id);
    if (!resp.error) {
      navigate('/app/idm/');
    }
  };

  return (
    <React.Fragment>
      <SEO title="IDM Indicators" keywords={[]} />

      <form>
        <Masthead location={location}>
          <Container className="my-4">
            <Row className="mb-4">
              <Col>
                <input
                  className="idm-name"
                  type="text"
                  onBlur={(e) => saveName(e)}
                  defaultValue={selfAssessment.name}
                />
              </Col>
              <Col className=" d-flex justify-content-end align-items-center">
                {selfAssessment.users &&
                  selfAssessment.users.map((user) => (
                    <Initials key={user.id} user={user} />
                  ))}
                <Initials user={selfAssessment.owner} />
                <Button
                  className="btn btn-primary cute-button no-arrow mx-1"
                  onClick={() => setShareModalShow(true)}
                >
                  <FaShare className="mr-1" />
                  Share
                </Button>
                <Button
                  className="btn btn-primary cute-button no-arrow mx-1"
                  onClick={() => setDeleteModalShow(true)}
                >
                  <FaTrash />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex flex-wrap">
                  {tabs.map((tab) => {
                    const now =
                      100 *
                      (progress[tab.group] /
                        orderedIndicators[tab.group].length);
                    return (
                      <div key={tab.area} className="w-25 pr-4 my-3">
                        <p className="mb-1">{tab.area}</p>
                        <div className="d-flex align-items-center">
                          <ProgressBar now={now} className="flex-grow-1" />
                          <div className="ml-2">
                            <strong>
                              {progress[tab.group]}/
                              {orderedIndicators[tab.group].length}
                            </strong>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="w-25 d-flex align-items-center justify-content-center">
                    <p className="">
                      <Link
                        to={`/app/idm/report/${selfAssessment.id}`}
                        className="standalone pt-2"
                      >
                        View Score Report
                      </Link>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Masthead>

        <Tab.Container activeKey={idmSection}>
          <div className="shadow sticky-top background-white">
            <Container className="mb-3">
              <Nav
                variant="pills"
                className="flex-nowrap d-none d-lg-flex"
                activeKey={idmSection}
              >
                {tabs.map((itm) => (
                  <Nav.Item key={itm.group}>
                    <Nav.Link
                      tab={itm.group}
                      eventKey={itm.group}
                      onClick={(e) => {
                        e.preventDefault();
                        scroller.scrollTo('scrollTop', {
                          smooth: true,
                          offset: -100,
                        });
                        setIdmSection(e.target.getAttribute('tab'));
                      }}
                    >
                      {itm.area}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Form.Group controlId="area" className="py-4 d-block d-lg-none">
                <Form.Label>Indicator</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => setIdmSection(e.target.value)}
                  value={idmSection}
                >
                  {tabs.map((itm) => (
                    <option key={itm.group} value={itm.group}>
                      {itm.area}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Container>
          </div>
          <Element name="scrollTop" />
          <Container fluid="xxl" className="px-5 idmContent">
            <Tab.Content>
              {tabs.map((itm) => {
                const sectionRefs = [
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                  useRef(null),
                ];
                let activeSection = useScrollSpy({
                  sectionElementRefs: sectionRefs,
                  offsetPx: -150,
                  defaultValue: 0,
                });
                return (
                  <Tab.Pane
                    title={itm.area}
                    eventKey={itm.group}
                    key={itm.group}
                    mountOnEnter={true}
                  >
                    <Row>
                      <Col
                        md={4}
                        lg={3}
                        xl={3}
                        className="pt-5 pr-5 pb-5 d-none d-lg-block"
                      >
                        <ListGroup
                          variant="light"
                          className="sticky-top shadow background-white"
                          style={{ top: '150px', zIndex: 1000 }}
                          id="idm-indicators"
                        >
                          <ListGroup.Item
                            className="background-idm-light-gray top-group-item"
                            variant="dark"
                          >
                            <span className="text-black">
                              <strong>{itm.group} Indicators</strong>
                            </span>
                          </ListGroup.Item>
                          {orderedIndicators[itm.group].map(
                            (indicator, idx) => (
                              <ListGroup.Item
                                className={`background-white ${
                                  activeSection == idx ? 'active' : ''
                                }`}
                                key={indicator.identifier}
                              >
                                <AnchorLink
                                  to={`/app/idm/${selfAssessment.id}/#indicator_${indicator.identifier}`}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: md
                                        .makeHtml(indicator.summary)
                                        .replace(/\<\/?p\>/g, ''),
                                    }}
                                  />
                                </AnchorLink>
                              </ListGroup.Item>
                            )
                          )}
                        </ListGroup>
                      </Col>
                      <Col md={8} lg={9} xl={9}>
                        <div className="d-flex align-items-center mt-5 my-4">
                          <img
                            src={`/images/icon_${itm.group}.svg`}
                            width="57"
                            alt=""
                          />
                          <h2 className="mb-0 pl-3">{itm.area}</h2>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: md.makeHtml(itm.content),
                          }}
                        />

                        <h2 className="my-5">{itm.area} Indicators</h2>
                        {orderedIndicators[itm.group].map((indicator, idx) => {
                          return indicator.category === 'infrastructure' ? (
                            <InfrastructureIndicator
                              indicator={indicator}
                              register={register}
                              formChanged={formChanged}
                              selfAssessment={selfAssessment}
                              key={idx}
                              sectionRef={sectionRefs[idx]}
                              setValue={setValue}
                            />
                          ) : (
                            <ImplementationIndicator
                              indicator={indicator}
                              register={register}
                              control={control}
                              formChanged={formChanged}
                              selfAssessment={selfAssessment}
                              setValue={setValue}
                              key={idx}
                              sectionRef={sectionRefs[idx]}
                            />
                          );
                        })}
                      </Col>
                    </Row>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Container>
        </Tab.Container>
      </form>
      <Modal
        show={showDeleteModal}
        onHide={handleDeleteModalClose}
        backdrop="static"
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h3">Delete {selfAssessment.name}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will delete the assessment and the associated scores report. You
          and anyone who has access to this assessment will no longer be able to
          view it.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => handleDeleteAssessment()}>
            Yes, Delete Assessment
          </Button>
          <Button variant="secondary" onClick={handleDeleteModalClose}>
            No, Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showShareModal}
        onHide={handleShareModalClose}
        backdrop="static"
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h3">{selfAssessment.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Invite your team to edit this assessment.  Please note that other team members can change and overwrite any existing answers on this assessment.  We recommend exporting your answers from <Link
                        to={`/app/idm/report/${selfAssessment.id}`}
                      >the score report page</Link> before sharing.</p>
          <Form onSubmit={(e) => shareFormChanged(e)}>
            <Form.Group controlId="emailAddress">
              <Form.Label>Email Addresses, comma separated</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email addresses"
                ref={registerShare}
                name="email"
              />
            </Form.Group>
            <Form.Group controlId="organization">
              <Form.Label>Optional Note</Form.Label>
              <Form.Control
                type="textarea"
                as="textarea"
                placeholder="Enter a note"
                ref={registerShare}
                name="note"
              />
            </Form.Group>
            <Button variant="primary" className="cute-button" type="submit">
              Share
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

Page.propTypes = {
  location: PropTypes.shape(),
};

Page.defaultProps = {
  location: null,
};

export default Page;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Card,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Masthead from '@/components/layout/masthead';
import SEO from '@/components/layout/seo';
import Accordion from 'react-bootstrap/Accordion';
import {
  AiOutlineDownload
} from 'react-icons/ai';

import { FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import { getAssessment, createAssessmentCSV, getAssessmentCSV } from '@/services/api';

import {
  total_cut_scores,
  infrastructure_cut_scores,
  implementation_cut_scores,
  cut,
  getOverviews,
  organizeIndicators,
  calculateReportData,
} from '@/services/idm';

const Page = ({ id }) => {
  const data = useStaticQuery(graphql`
    {
      allIndicatorsXlsxIndicators {
        nodes {
          identifier
          category
          group
          number
          type
          summary
          in_place
          initial
          area
          content
          development
          established
        }
      }
    }
  `);
  const allIndicators = data.allIndicatorsXlsxIndicators.nodes;
  const tabs = getOverviews(allIndicators);

  const openState = {};
  tabs.map((tab) => {
    const [open, setOpen] = useState(false);
    openState[tab.group] = { open, setOpen };
  });

  const [currentResponse, setCurrentResponse] = useState({});
  const [report, setReport] = useState(false);
  const [assessmentCSV, setAssessmentCSV] = useState({});
  

  useEffect(() => {
    async function getData() {
      let ca = await getAssessment(id);
      let createCSV = await createAssessmentCSV(id);
      let response = await getAssessmentCSV(id);
      if (ca.error) {
        navigate('/app/idm/');
      } else {
        setCurrentResponse(ca.data);
        setReport(calculateReportData(ca.data.response, allIndicators));
        setAssessmentCSV(response.data);
      }
    }
    getData();
  }, [id]);

  function getCSV(){
    const url = window.URL.createObjectURL(new Blob([assessmentCSV]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'assessmentScoreReport.csv');
    document.body.appendChild(link);
    link.click();
  }

  return (
    <React.Fragment>
      <SEO title="IDM Score Report" keywords={[]} />
      <Masthead>
        <Container className="my-4">
          <Row>
            <Col lg={7}>
              <p>
                <small>
                  <Link to="/app/idm/">Implementation Development Map</Link>
                  {' | '}
                  <Link to={`/app/idm/${currentResponse.id}`}>
                    {currentResponse.name}
                  </Link>
                  {' | '}
                  IDM Score Report
                </small>
              </p>
              <h2>IDM Score Report</h2>
              <p>
                The IDM Score Report can help you identify system strengths and
                challenges and uncover gaps in data. Reviewing the Score Report
                in light of your state's early childhood education context can
                help you determine priorities for creating and improving
                policies and practices to strengthen early learning programs for
                all children in your state. 
              </p>
            </Col>
            <Col className="" lg={{ span: 3, offset: 2 }}>
              <div className="shadow featured-link mt-5">
                <Link to="/tools/?topic=Implement" className="standalone">
                  View Tools to Help Implement, Improve, & Advocate
                </Link>
              </div>
            </Col>
          </Row>
          <h4 className="my-3"><a href="" onClick={getCSV} >Export Assessment Scores to CSV <AiOutlineDownload className="dl-icon" /></a></h4>
        </Container>
      </Masthead>
      <Container>
        <Row>
          <Col>
            <h4 className="mt-5 mb-3">Indicator Overview Score</h4>
            <Row className="d-none d-lg-flex">
              <Col lg={3}></Col>
              <Col lg={3} className="tag">
                Element Total
              </Col>
              <Col lg={3} className="tag">
                Infrastructure
              </Col>
              <Col lg={3} className="tag">
                Implementation
              </Col>
            </Row>
            <Accordion defaultActiveKey="0" className="mb-5">
              {report &&
                tabs.map((element) => {
                  let {
                    total_score,
                    total_implementation_score,
                    total_infrastructure_score,
                    my_score,
                    my_infrastructure_score,
                    my_implementation_score,
                    warning,
                    all_indicators,
                    implementation_indicators,
                    infrastructure_indicators,
                  } = report[element.group];
                  let open = openState[element.group].open;
                  let setOpen = openState[element.group].setOpen;
                  return (
                    <React.Fragment key={element.identifier}>
                      <Accordion.Toggle
                        as={Card}
                        eventKey={element.identifier}
                        className={`mt-3 toggle ${open ? 'active' : ''}`}
                        onClick={() => setOpen(!open)}
                      >
                        <Card.Body className="p-0">
                          <Row>
                            <Col
                              md={3}
                              className="d-flex align-items-center py-3"
                            >
                              <img
                                src={`/images/icon_${element.group}.svg`}
                                width="40"
                                alt=""
                                className="mr-3 ml-3"
                              />
                              <p className="mb-0">
                                <strong>{element.area}</strong>
                              </p>
                            </Col>
                            <Col
                              md={3}
                              className="background-idm-light-gray py-3 px-4"
                            >
                              <span className="tag">
                                {cut(my_score, total_cut_scores[element.group])}
                              </span>
                              <div className="d-flex align-items-center">
                                <ProgressBar
                                  now={(
                                    (my_score / total_score) *
                                    100
                                  ).toFixed()}
                                  className="flex-grow-1"
                                />
                                <span className="ml-2">
                                  {((my_score / total_score) * 100).toFixed()}%
                                </span>
                                {warning && (
                                  <span className="pl-2">
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>
                                          <div>
                                            <div>
                                              {my_score} out of {total_score}
                                            </div>
                                            <hr className="my-1" />
                                            <div>Interpret with Caution</div>
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <FaExclamationTriangle />
                                    </OverlayTrigger>
                                  </span>
                                )}
                                {!warning && (
                                  <span className="pl-2">
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>
                                          {my_score} out of {total_score}
                                        </Tooltip>
                                      }
                                    >
                                      <FaInfoCircle />
                                    </OverlayTrigger>
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col className="py-3 px-4 d-none d-lg-block">
                              <span className="tag">
                                {cut(
                                  my_infrastructure_score,
                                  infrastructure_cut_scores[element.group]
                                )}
                              </span>
                              <div className="d-flex align-items-center">
                                <ProgressBar
                                  now={(
                                    (my_infrastructure_score /
                                      total_infrastructure_score) *
                                    100
                                  ).toFixed()}
                                  className="flex-grow-1"
                                />
                                <span className="ml-2">
                                  {(
                                    (my_infrastructure_score /
                                      total_infrastructure_score) *
                                    100
                                  ).toFixed()}
                                  %
                                </span>
                                <span className="pl-2">
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip>
                                        <div>
                                          <div>
                                            {my_infrastructure_score} out of{' '}
                                            {total_infrastructure_score}
                                          </div>
                                        </div>
                                      </Tooltip>
                                    }
                                  >
                                    <FaInfoCircle />
                                  </OverlayTrigger>
                                </span>
                              </div>
                            </Col>
                            <Col className="py-3 px-4 d-none d-lg-block">
                              {implementation_indicators.length > 0 && (
                                <React.Fragment>
                                  <span className="tag">
                                    {cut(
                                      my_implementation_score,
                                      implementation_cut_scores[element.group]
                                    )}
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <ProgressBar
                                      now={(
                                        (my_implementation_score /
                                          total_implementation_score) *
                                        100
                                      ).toFixed()}
                                      className="flex-grow-1"
                                    />
                                    <span className="ml-2 mr-3">
                                      {(
                                        (my_implementation_score /
                                          total_implementation_score) *
                                        100
                                      ).toFixed()}
                                      %
                                    </span>
                                    {warning && (
                                      <span className="pr-4">
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip>
                                              <div>
                                                <div>
                                                  {my_implementation_score} out
                                                  of{' '}
                                                  {total_implementation_score}
                                                </div>
                                                <hr className="my-1" />
                                                <div>
                                                  Interpret with Caution
                                                </div>
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <FaExclamationTriangle />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                    {!warning && (
                                      <span className="pr-4">
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip>
                                              <div>
                                                <div>
                                                  {my_implementation_score} out
                                                  of{' '}
                                                  {total_implementation_score}
                                                </div>
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <FaInfoCircle />
                                        </OverlayTrigger>
                                      </span>
                                    )}
                                  </div>
                                </React.Fragment>
                              )}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={element.identifier}>
                        <Card
                          className="p-3 p-lg-5"
                          style={{ overflow: 'auto' }}
                        >
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th></th>
                                <th
                                  colSpan={infrastructure_indicators.length}
                                  className="text-center"
                                >
                                  Infrastructure Indicators
                                </th>
                                {implementation_indicators.length > 0 && (
                                  <th
                                    colSpan={implementation_indicators.length}
                                    className="text-center"
                                  >
                                    Implementation Indicators
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td> </td>
                                {all_indicators.map((indicator) => {
                                  return (
                                    <td
                                      key={indicator.identifier}
                                      className="text-center"
                                    >
                                      {indicator.number}
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <td>
                                  <strong>Scores</strong>
                                </td>
                                {all_indicators.map((indicator) => {
                                  let this_score =
                                    currentResponse.response[
                                      `indicator_${indicator.identifier}`
                                    ];
                                  if (
                                    currentResponse.response[
                                      `indicator_${indicator.identifier}_source`
                                    ] == 'insufficient'
                                  ) {
                                    this_score = 0;
                                  }
                                  let warning =
                                    currentResponse.response[
                                      `indicator_${indicator.identifier}_source`
                                    ] == 'indirect';
                                  return (
                                    <td
                                      key={indicator.identifier}
                                      width={
                                        (100 / all_indicators.length) * 100
                                      }
                                      className={`text-center ${
                                        (this_score && this_score <= 1) ||
                                        warning
                                          ? 'background-idm-warning'
                                          : 'background-idm-light-gray'
                                      }`}
                                    >
                                      {this_score}{' '}
                                      {warning && <FaExclamationTriangle />}
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                          <div className="d-none d-md-block my-3">
                            <strong>Scoring Key</strong>
                          </div>
                          <div className="d-flex  flex-wrap">
                            <div className="px-3 px-md-0">
                              <strong>0</strong>
                              <br />
                              Unscored
                            </div>
                            <div className="px-3">
                              <strong>1</strong>
                              <br />
                              Initial Stages
                            </div>
                            <div className="px-3">
                              <strong>2</strong>
                              <br />
                              In Development
                            </div>
                            <div className="px-3">
                              <strong>3</strong>
                              <br />
                              In Place
                            </div>
                            <div className="px-3">
                              <strong>4</strong>
                              <br />
                              Well Established
                            </div>
                          </div>
                          <p className="px-3 px-md-0 my-3">
                            <FaExclamationTriangle className="mr-2" />
                            Rating is based on indirect sources and/or
                            non-representative data; interpret results with
                            caution.
                          </p>
                        </Card>
                      </Accordion.Collapse>
                    </React.Fragment>
                  );
                })}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

Page.propTypes = {
  location: PropTypes.shape(),
};

Page.defaultProps = {
  location: null,
};

export default Page;

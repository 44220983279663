import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export default function IdmMastheadContent() {
  return (
    <Container className="my-2">
      <Row>
        <Col lg={6}>
          <h2>Implementation Development Map</h2>
          <p>
            The <strong>Implementation Development Map (IDM)</strong> is a
            process-oriented tool designed to help state leaders implement
            high-quality equitable pre-kindergarten (pre-K) systems and
            practices that produce positive early learning opportunities for
            every child.
          </p>
          <p>
            To understand the IDM conceptual framework{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="/downloads/IDM-At-A-Glance.pdf"
            >
              view the IDM-At-A-Glance
            </a>
            , PDF, 740 KB
          </p>
        </Col>
        <Col lg={{ span: 3, offset: 2 }}>
          <div className="shadow featured-link mt-4">
            <Link to="/downloads/IDM-user-guide.pdf" className="standalone">
              IDM User Guide
            </Link>
          </div>
          <div className="shadow featured-link mt-4">
          <Link to="/downloads/PPI-IDM-Final.pdf" className="standalone">
              PDF of the IDM
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

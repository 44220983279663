import React from 'react';
import PropTypes from 'prop-types';
import Showdown from 'showdown';
import { Form } from 'react-bootstrap';
import { useWatch, useForm } from 'react-hook-form';

export default function ImplementationIndicator({
  indicator,
  register,
  formChanged,
  control,
  selfAssessment,
  setValue,
  sectionRef,
}) {
  const md = new Showdown.Converter();

  const score = useWatch({
    control: control,
    name: `indicator_${indicator.identifier}`,
    defaultValue: null,
  });

  const source = useWatch({
    control: control,
    name: `indicator_${indicator.identifier}_source`,
    defaultValue: null,
  });

  let name = 'Unknown';
  if (
    selfAssessment.response &&
    selfAssessment.response[`indicator_${indicator.identifier}_updated_by`]
  ) {
    let userId =
      selfAssessment.response[`indicator_${indicator.identifier}_updated_by`];
    if (selfAssessment.owner.id == userId) {
      name =
        selfAssessment.owner.first_name + ' ' + selfAssessment.owner.last_name;
    }
  }

  return (
    <div
      key={indicator.identifier}
      id={`indicator_${indicator.identifier}`}
      className="my-5"
      ref={sectionRef}
    >
      <p className="text-uppercase mb-0">
        <strong>
          Indicator {indicator.number} <span className="px-2">|</span>
          <span className="text-idm-pink">{indicator.area}</span>
        </strong>
      </p>{' '}
      <h3
        dangerouslySetInnerHTML={{
          __html: md.makeHtml(indicator.summary),
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: md.makeHtml(indicator.content),
        }}
      />
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td className="w-25">
              <div className="custom-control custom-radio mb-0">
                <input
                  type="radio"
                  ref={register}
                  name={`indicator_${indicator.identifier}`}
                  id={`indicator_${indicator.identifier}Initial`}
                  value="1"
                  onChange={() => formChanged(indicator.identifier, {"score": "1"})}
                  disabled={source === 'insufficient'}
                  className="custom-control-input"
                />
                <label
                  htmlFor={`indicator_${indicator.identifier}Initial`}
                  className="tag mb-0 custom-control-label"
                ></label>
              </div>

              <label
                htmlFor={`indicator_${indicator.identifier}Initial`}
                className="tag mt-2"
              >
                Initial Stages
              </label>
            </td>
            <td className="w-25">
              <div className="custom-control custom-radio mb-0">
                <input
                  type="radio"
                  ref={register}
                  name={`indicator_${indicator.identifier}`}
                  id={`indicator_${indicator.identifier}Development`}
                  value="2"
                  disabled={source == 'insufficient'}
                  onChange={() => formChanged(indicator.identifier, {"score": "2"})}
                  className="custom-control-input"
                />{' '}
                <label
                  htmlFor={`indicator_${indicator.identifier}Development`}
                  className="tag mb-0 custom-control-label"
                ></label>
              </div>

              <label
                htmlFor={`indicator_${indicator.identifier}Development`}
                className="tag mt-2"
              >
                In Development
              </label>
            </td>
            <td className="w-25">
              <div className="custom-control custom-radio mb-0">
                <input
                  type="radio"
                  ref={register}
                  value="3"
                  name={`indicator_${indicator.identifier}`}
                  id={`indicator_${indicator.identifier}InPlace`}
                  disabled={source === 'insufficient'}
                  onChange={() => formChanged(indicator.identifier, {"score": "3"})}
                  className="custom-control-input"
                />{' '}
                <label
                  htmlFor={`indicator_${indicator.identifier}InPlace`}
                  className="tag mb-0 custom-control-label"
                ></label>
              </div>

              <label
                htmlFor={`indicator_${indicator.identifier}InPlace`}
                className="tag mt-2"
              >
                In Place
              </label>
            </td>
            <td className="w-25">
              <div className="custom-control custom-radio mb-0">
                <input
                  type="radio"
                  ref={register}
                  name={`indicator_${indicator.identifier}`}
                  id={`indicator_${indicator.identifier}Established`}
                  value="4"
                  disabled={source === 'insufficient'}
                  onChange={() => formChanged(indicator.identifier, {"score": "4"})}
                  className="custom-control-input"
                />{' '}
                <label
                  htmlFor={`indicator_${indicator.identifier}Established`}
                  className="tag mb-0 custom-control-label"
                ></label>
              </div>
              <label
                htmlFor={`indicator_${indicator.identifier}Established`}
                className="tag mt-2"
              >
                Well Established
              </label>
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              <label
                htmlFor={`indicator_${indicator.identifier}Initial`}
                dangerouslySetInnerHTML={{
                  __html: md.makeHtml(indicator.initial),
                }}
              />
            </td>
            <td>
              {' '}
              <label
                htmlFor={`indicator_${indicator.identifier}Development`}
                dangerouslySetInnerHTML={{
                  __html: md.makeHtml(indicator.development),
                }}
              />
            </td>
            <td>
              {' '}
              <label
                htmlFor={`indicator_${indicator.identifier}InPlace`}
                dangerouslySetInnerHTML={{
                  __html: md.makeHtml(indicator.in_place),
                }}
              />
            </td>
            <td>
              {' '}
              <label
                htmlFor={`indicator_${indicator.identifier}Established`}
                dangerouslySetInnerHTML={{
                  __html: md.makeHtml(indicator.established),
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>
          Select one of the following options to complete your selection:{' '}
        </strong>
      </p>
      <div className="d-flex my-2 custom-control custom-radio">
        <input
          type="radio"
          ref={register}
          value="direct"
          name={`indicator_${indicator.identifier}_source`}
          id={`indicator_${indicator.identifier}Direct`}
          onChange={() => formChanged(indicator.identifier, {"source": "direct"})}
          disabled={!score}
          className="mt-2 mr-2 custom-control-input"
        />
        <label
          htmlFor={`indicator_${indicator.identifier}Direct`}
          className="custom-control-label"
        >
          This rating is based on direct review of high-quality data from a
          state-wide, representative sample or universe survey.
          <p className="mt-2">
            Note name of data source(s):
            <input
              type="text"
              ref={register}
              name={`indicator_${indicator.identifier}_specific_source`}
              id={`indicator_${indicator.identifier}SpecificSource`}
              onBlur={() => formChanged(indicator.identifier, {'specific_source': indicator})}
              disabled={source != 'direct'}
              size="50"
              className=""
            />
          </p>
        </label>
      </div>
      <div className="d-flex my-2 custom-control custom-radio">
        <input
          type="radio"
          ref={register}
          value="indirect"
          name={`indicator_${indicator.identifier}_source`}
          id={`indicator_${indicator.identifier}Indirect`}
          onChange={() => formChanged(indicator.identifier, {"source": "indirect"})}
          disabled={!score}
          className="mt-2 mr-2 custom-control-input"
        />
        <label
          htmlFor={`indicator_${indicator.identifier}Indirect`}
          className="custom-control-label"
        >
          This rating is based on indirect knowledge from programs/districts,
          such as administrator assurances, and/or a less-than-representative
          set of data.
        </label>
      </div>
      <div className="d-flex my-2 custom-control custom-radio">
        <input
          type="radio"
          ref={register}
          value="insufficient"
          name={`indicator_${indicator.identifier}_source`}
          id={`indicator_${indicator.identifier}Insufficient`}
          onChange={() => formChanged(indicator.identifier, {"source": "insufficient"})}
          disabled={parseInt(score) >= 1}
          className="mt-2 mr-2 custom-control-input"
        />
        <label
          htmlFor={`indicator_${indicator.identifier}Insufficient`}
          className="custom-control-label"
        >
          There are insufficient data available to score this indicator.
        </label>
      </div>
      <Form.Group
        className="mb-3"
        controlId={`indicator_${indicator.identifier}_notes`}
      >
        <Form.Label>Notes:</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          ref={register}
          name={`indicator_${indicator.identifier}_notes`}
          onBlur={() => formChanged(indicator.identifier, {'notes': indicator.identifier})}
        />
      </Form.Group>
      {selfAssessment.response &&
        (selfAssessment.response[`indicator_${indicator.identifier}`] ||
          selfAssessment.response[
            `indicator_${indicator.identifier}_source`
          ] === 'insufficient') && (
          <p>
            <small>
              <em>
                Last updated by {name} on{' '}
                {
                  selfAssessment.response[
                    `indicator_${indicator.identifier}_updated_at`
                  ]
                }
                .{' '}
                <a
                  onClick={(e) => {
                    setValue(`indicator_${indicator.identifier}_source`, null);
                    setValue(`indicator_${indicator.identifier}_notes`, null);
                    setValue(`indicator_${indicator.identifier}`, null);
                    setValue(
                      `indicator_${indicator.identifier}_updated_at`,
                      null
                    );
                    setValue(
                      `indicator_${indicator.identifier}_updated_by`,
                      null
                    );

                    e.preventDefault();
                    formChanged(indicator.identifier, null);
                  }}
                  href="#"
                >
                  Reset
                </a>
              </em>
            </small>
          </p>
        )}
    </div>
  );
}

ImplementationIndicator.propTypes = {
  indicator: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  formChanged: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  selfAssessment: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  sectionRef: PropTypes.object.isRequired,
};

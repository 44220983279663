import React, { useState} from 'react';
import { AiOutlineDownload, AiOutlineFilePdf } from 'react-icons/ai';

const LoginContent = () => {

  //can't use window in gatsby
  //let screenWidth = window.matchMedia('(max-width: 540px)');

  let colSize = 'col-3';
  
  //screenWidth.matches ? colSize = 'mx-3' : colSize = 'col-3'; 

  // HARD CODED LINK TO data-landscape xlsx file, allows xls to be opened directly in browser window
  // const dataLandscapeGoogleViewUrl = "https://docs.google.com/viewer?url=https://upk-improvement.org/downloads/data-landscape-template.xlsx";

  let startIDMContent = `To start using the IDM, please log in or register an account. A valid email address
   is required to save IDM scoring information and to collaborate with your team. Accounts are forever 
   free of charge and available to all.\n\n`;
  let responseContent = `The following resources may help you engage stakeholders and gather the data 
  necessary to complete your assessment.`;

  let fullContent = responseContent;
  const url = typeof window !== 'undefined' ? window.location.href : '';
  if (!url.includes('app')){
    fullContent = [startIDMContent, <br/>, <br/>, responseContent];
   }
  return (
    <div>
      <h3>Getting Started with the IDM</h3>
      <p>{fullContent}</p>

      <div className="d-flex align-items-start flex-wrap">
        {/* previous className position-relative col */}
        <div className={colSize}>
          <h5 className="my-1">
            <a
              href="/downloads/components-of-the-IDM-tool.pdf"
              className="stretched-link text-black"
            >
              Components of the IDM Tool
            </a>
          </h5>
          <div className="mb-4 fileinfo d-flex align-items-center">
            <AiOutlineFilePdf size="20px" />
            <span className="d-inline-block pt-1 ml-1">PDF, 158 KB</span>
            <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
          </div>
        </div>

        <div className={colSize}>
          <h5 className="my-1">
            <a
              href="/downloads/equity-driven-quality-improvement.pdf"
              className="stretched-link text-black"
            >
              Equity Driven Quality Improvement
            </a>
          </h5>
          <div className="mb-4 fileinfo d-flex align-items-center">
            <AiOutlineFilePdf size="20px" />
            <span className="d-inline-block pt-1 ml-1">PDF, 147 KB</span>
            <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
          </div>
        </div>

        <div className={colSize}>
          <h5 className="my-1">
            <a
              href="/downloads/getting-ready-to-score-the-IDM.pdf"
              className="stretched-link text-black"
            >
              Getting Ready to Score the IDM
            </a>
          </h5>
          <div className="mb-4 fileinfo d-flex align-items-center">
            <AiOutlineFilePdf size="20px" />
            <span className="d-inline-block pt-1 ml-1">PDF, 184 KB</span>
            <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
          </div>
        </div>

        <div className={colSize} >
          <h5 className="my-1">
            <a
              href="/downloads/high-quality-data-definitions.pdf"
              className="stretched-link text-black"
            >
              High-Quality Data Definitions
            </a>
          </h5>
          <div className="mb-4 fileinfo d-flex align-items-center">
            <AiOutlineFilePdf size="20px" />
            <span className="d-inline-block pt-1 ml-1">PDF, 209 KB</span>
            <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
          </div>
        </div>
        {/* </div>
        
        <div className="row"> */}

        <div className={colSize}>
          <h5 className="my-1">
              <a
                href="/downloads/data-landscape-template.xlsx"
                className="stretched-link text-black"
              >
              Data Landscape Template
            </a>
          </h5>
          <div className="mb-4 fileinfo d-flex align-items-center">
            <AiOutlineFilePdf size="20px" />
            <span className="d-inline-block pt-1 ml-1">XLS, 132 KB</span>
            <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
          </div>
        </div>

        <div className={colSize}>
          <h5 className="my-1">
            <a
              href="/downloads/IDM-program-survey.pdf"
              className="stretched-link text-black"
            >
              Statewide Program Survey
            </a>
          </h5>
          <div className="mb-4 fileinfo d-flex align-items-center">
            <AiOutlineFilePdf size="20px" />
            <span className="d-inline-block pt-1 ml-1">PDF, 374 KB</span>
            <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
          </div>
        </div>

        <div className={colSize}>
          <h5 className="my-1">
            <a
              href="/downloads/PDG-IDM-Crosswalk.pdf"
              className="stretched-link text-black"
            >
              PDG B-5 and IDM Crosswalk Document
            </a>
          </h5>
          <div className="mb-4 fileinfo d-flex align-items-center">
            <AiOutlineFilePdf size="20px" />
            <span className="d-inline-block pt-1 ml-1">PDF, 192 KB</span>
            <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
          </div>
        </div>

        <div className={colSize}>
          <h5 className="my-1">
            <a
              href="/downloads/NIEER-Crosswalk-IDM-11x17-draft3.pdf"
              className="stretched-link text-black"
            >
              National Institute for Early Education Research (NIEER) Benchmarks Crosswalk
            </a>
          </h5>
          <div className="mb-4 fileinfo d-flex align-items-center">
            <AiOutlineFilePdf size="20px" />
            <span className="d-inline-block pt-1 ml-1">PDF, 221 KB</span>
            <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
          </div>
        </div>

      </div>
    </div>
  );
};

export default LoginContent;

export const calculateProgress = (values, indicators) => {
  const orderedIndicators = organizeIndicators(indicators);
  const tmpProgress = {};
  const keys = Object.keys(orderedIndicators);
  const complete = {};
  for (let g = 0; g < keys.length; g += 1) {
    const currentKey = keys[g];
    if (!tmpProgress[currentKey]) {
      tmpProgress[currentKey] = 0;
    }
    for (let h = 0; h < orderedIndicators[currentKey].length; h += 1) {
      if (values[`indicator_${orderedIndicators[currentKey][h].identifier}`]) {
        tmpProgress[currentKey] += 1;
      }
    }
    if (tmpProgress[currentKey] == orderedIndicators[currentKey].length) {
      complete[currentKey] = true;
    } else {
      complete[currentKey] = false;
    }
  }
  return tmpProgress;
};

export const getOverviews = (allIndicators) => {
  return allIndicators.filter((itm) => itm.type === 'overview');
};
export const getIndicators = (allIndicators) => {
  return allIndicators.filter((itm) => itm.type === 'indicator');
};

export const calculateReportData = (response, allIndicators) => {
  const elements = getOverviews(allIndicators);
  const orderedIndicators = organizeIndicators(allIndicators);
  const reportData = {};
  elements.map((element) => {
    let all_indicators = orderedIndicators[element.group];
    let implementation_indicators = all_indicators.filter(
      (itm) => itm.category == 'implementation'
    );
    let infrastructure_indicators = all_indicators.filter(
      (itm) => itm.category == 'infrastructure'
    );

    let total_score = all_indicators.length * 4;
    let total_implementation_score = implementation_indicators.length * 4;
    let total_infrastructure_score = infrastructure_indicators.length * 4;

    let count_responses = all_indicators.reduce((val, indicator) => {
      return val + (response[`indicator_${indicator.identifier}`] ? 1 : 0);
    }, 0);

    let complete = all_indicators.length === count_responses;

    let my_score = all_indicators.reduce((val, indicator) => {
      return val + parseInt(response[`indicator_${indicator.identifier}`] ?? 0);
    }, 0);

    let my_infrastructure_score = infrastructure_indicators.reduce(
      (val, indicator) => {
        return (
          val + parseInt(response[`indicator_${indicator.identifier}`] ?? 0)
        );
      },
      0
    );

    let my_implementation_score = 0;
    if (implementation_indicators.length) {
      my_implementation_score = implementation_indicators.reduce(
        (val, indicator) => {
          return (
            val + parseInt(response[`indicator_${indicator.identifier}`] ?? 0)
          );
        },
        0
      );
    }

    let warning =
      implementation_indicators.filter(
        (itm) =>
          response[`indicator_${itm.identifier}_source`] == 'insufficient'
      ).length > 0;
    reportData[element.group] = {
      total_score,
      total_implementation_score,
      total_infrastructure_score,
      my_score,
      my_infrastructure_score,
      my_implementation_score,
      warning,
      all_indicators,
      implementation_indicators,
      infrastructure_indicators,
      complete,
    };
  });
  return reportData;
};

export const organizeIndicators = (allIndicators) => {
  const indicators = getIndicators(allIndicators);
  const orderedIndicators = {};
  for (let i = 0; i < indicators.length; i += 1) {
    const currentIndicator = indicators[i];
    if (!orderedIndicators[currentIndicator.group]) {
      orderedIndicators[currentIndicator.group] = [];
    }
    orderedIndicators[currentIndicator.group].push(currentIndicator);
  }
  return orderedIndicators;
};

export const total_cut_scores = {
  PD: [22, 32],
  DDDM: [18, 26],
  PL: [18, 26],
  IL: [14, 20],
  HQT: [21, 29],
  RBC: [24, 35],
  FCA: [24, 35],
};
export const infrastructure_cut_scores = {
  PD: [12, 17],
  DDDM: [18, 26],
  PL: [18, 26],
  IL: [14, 20],
  HQT: [16, 23],
  RBC: [12, 17],
  FCA: [10, 14],
};
export const implementation_cut_scores = {
  PD: [10, 14],
  HQT: [4, 5],
  RBC: [12, 17],
  FCA: [14, 20],
};

export const cut = (score, range) => {
  if (score < range[0]) return 'Low';
  else if (score > range[1]) return 'High';
  else return 'Mid';
};

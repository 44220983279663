import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from '@/components/PrivateRoute';

import DefaultLayout from '@/layouts/default';

import ProfilePage from '@/components/auth_required/profile';
import IdmIndex from '@/components/auth_required/idm/index';
import Idm from '@/components/auth_required/idm/idm';
import Report from '@/components/auth_required/idm/report';

const App = ({ location }) => {
  return (
    <DefaultLayout pageInfo={location}>
      <Router basepath="/app">
        <PrivateRoute path="/profile" component={ProfilePage} />
        <PrivateRoute path="/idm/report/:id" component={Report} />
        <PrivateRoute path="/idm/:id" component={Idm} />
        <PrivateRoute path="/idm" component={IdmIndex} />
      </Router>
    </DefaultLayout>
  );
};
export default App;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import SEO from '@/components/layout/seo';
import { useForm } from 'react-hook-form';
import { logout, updateUser, updatePassword, getUser } from '@/services/api';
import { navigate } from 'gatsby-link';

const ProfilePage = () => {
  const { register, getValues, setValue } = useForm();
  const {
    register: registerPW,
    getValues: getValuesPW,
    setValue: setValuePW,
  } = useForm();
  const [submittingProfile, setSubmittingProfile] = useState(false);
  const [submittingPassword, setSubmittingPassword] = useState(false);
  const [pwError, setPwError] = useState(false);
  const [userError, setUserError] = useState(false);

  useEffect(() => {
    let me = getUser();
    setValue('first_name', me.first_name);
    setValue('last_name', me.last_name);
    setValue('organization', me.organization);
    setValue('email', me.email);
    setValue('state', me.state);
    setValue('id', me.id);
    setValue('email_opt_in', JSON.parse(me.email_opt_in));
  }, []);

  const formChanged = async (e, form) => {
    e.preventDefault();
    if (form === 'password') {
      setSubmittingPassword(true);
      setPwError(false);
      let pwv = getValuesPW();
      if (pwv.newPassword != pwv.confirmPassword) {
        setPwError('The new and confirmation passwords do not match.');
      } else {
        let res = await updatePassword(getValuesPW());
        if (!res.error) {
          setPwError('Password successfully updated.');
          setValuePW('oldPassword', '');
          setValuePW('newPassword', '');
          setValuePW('confirmPassword', '');
        } else {
          let err = '';
          err = Object.entries(res.errors).map((itm) => {
            return err + itm[1] + '\n';
          });
          setPwError(err.join(' '));
        }
      }
      setSubmittingPassword(false);
    } else if (form === 'profile') {
      setSubmittingProfile(true);
      setPwError(false);
      let res = await updateUser(getValues());
      if (!res.error) {
        setUserError('Profile successfully updated.');
      } else {
        let err = '';
        err = Object.entries(res.errors).map((itm) => {
          return err + itm[1] + '<br />';
        });
        setUserError(err.join(' '));
      }
      setSubmittingProfile(false);
    }
  };
  return (
    <React.Fragment>
      <SEO title="My Profile" />

      <Container className="mt-5 pb-5">
        <Row>
          <Col>
            <h2>My Profile</h2>
          </Col>
          <Col className="text-right">
            <p>
              <a
                className="btn btn-primary cute-button"
                onClick={(e) => {
                  logout();
                  navigate('/login');
                }}
              >
                Log out
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <h4>Personal Information</h4>
            <p>All fields are required.</p>
            {userError && (
              <div
                className="alert alert-warning"
                dangerouslySetInnerHTML={{ __html: userError }}
              ></div>
            )}{' '}
            <Form.Control type="hidden" ref={register} name="id" />
            <Form onSubmit={(e) => formChanged(e, 'profile')}>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  ref={register}
                  name="first_name"
                />
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  ref={register}
                  name="last_name"
                />
              </Form.Group>

              <Form.Group controlId="emailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email"
                  ref={register}
                  name="email"
                />
              </Form.Group>
              <Form.Group controlId="organization">
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter organization"
                  ref={register}
                  name="organization"
                />
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label>State/Province</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter State/Province (e.g. WA, OH, CA)"
                  ref={register}
                  name="state"
                />
              </Form.Group>
              <Button variant="primary" className="cute-button" type="submit">
                {submittingProfile ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Save Changes</span>
                )}
              </Button>
            </Form>
          </Col>
          <Col lg={{ offset: 1, span: 5 }} className="mt-5 mt-lg-0">
            <h4>Email Notifications</h4>
            <Form.Group controlId="emailOptIn">
                <Form.Check 
                  type="checkbox" 
                  label="Uncheck this box to opt out of promotional email materials from the Partnership for Pre-K Improvement Team" 
                  ref={register} 
                  name="email_opt_in"
                  />
            </Form.Group>
            <h4>Password Reset</h4>
            Your password must:
            <ul>
              <li>be at least 8 characters</li>
              <li>contain at least one uppercase and one lowercase letter</li>
              <li>contain at least one numeric character</li>
              <li>contain at least one symbol</li>
            </ul>
            {pwError && (
              <div
                className="alert alert-warning"
                dangerouslySetInnerHTML={{ __html: pwError }}
              ></div>
            )}{' '}
            <Form onSubmit={(e) => formChanged(e, 'password')}>
              {/*              <Form.Group controlId="originalPassword">
                <Form.Label>Original Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter original password"
                  ref={registerPW}
                  name="oldPassword"
                />
            </Form.Group>*/}
              <Form.Group controlId="newPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  ref={registerPW}
                  name="newPassword"
                />
              </Form.Group>
              <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Re-enter new password"
                  ref={registerPW}
                  name="confirmPassword"
                />
              </Form.Group>
              <Button variant="primary" className="cute-button" type="submit">
                {submittingPassword ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Reset Password</span>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ProfilePage;
